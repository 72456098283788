import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { ApiService } from '../api.service';
import { UserService } from '../user.service';

@Component({
  selector: 'app-select-contact',
  templateUrl: './select-contact.page.html',
  styleUrls: ['./select-contact.page.scss'],
})
export class SelectContactPage implements OnInit {

  @Input() contacts: any;
  @Input() cartData: any;
  @Input() link_id: any;
  selectedContact: any = 'none';
  company_name: any;
  person_name: any;
  address: any;
  city: any;
  state: any;
  pincode: any;
  remarks: any;
  constructor(public modal: ModalController, public user: UserService, public api: ApiService) {

  }

  ngOnInit() {

  }

  close(){
    this.modal.dismiss({});
  }

  populateAvlData() {
    console.log(this.cartData);
    if (this.selectedContact.first_name || this.selectedContact.last_name)
      this.person_name = this.selectedContact.first_name + " " + this.selectedContact.last_name;
    if (this.selectedContact.company_name)
      this.company_name = this.selectedContact.company_name;
    if (this.selectedContact.address)
      this.address = this.selectedContact.address;
    if (this.selectedContact.pincode && this.selectedContact.pincode.length == 6)
      this.pincode = this.selectedContact.pincode;
    else
      this.pincode = "";
  }

  // this.modal.dismiss({
  //   userData : {
  //     "company_name":this.company_name,
  //     "name":this.person_name,
  //     "address":this.address,
  //     "city":"",
  //     "state":"",
  //     "pincode":this.pincode
  //   }
  // });

  proceed() {

    var array = [];
    for (let x of this.cartData) {
      for (let y of x.data) {
        for (let z of y.data) {
          for (let f of z.data) {
            array.push({
              'link_id': this.link_id,
              'brand_id': f.brand_id,
              'EAN_code': f.EAN_code,
              'no_of_sets': 1,
              'approved_qty': 0,

            });
          }
        }
      }
      var userData = {
        "company_name": this.company_name,
        "name": this.person_name,
        "address": this.address,
        "city": "",
        "state": "",
        "pincode": this.pincode
      }
      var body = new FormData;
      body.append('cartData', JSON.stringify(array));
      body.append('phone_number', this.user.userData.phone);
      body.append('user_data', JSON.stringify(userData));
      body.append('remarks', this.remarks);

      this.api.placeOrder(body).subscribe((response:any)=>{
        this.modal.dismiss({
          orderPlace: true
        });
      },
      error=>{
        this.modal.dismiss({
          orderPlace: false
        });
      }
    )

    }

  }


}
