import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ModalController } from '@ionic/angular';
import { from } from 'rxjs';
import { reduce } from 'rxjs/operators';
import { ApiService } from '../api.service';

@Component({
  selector: 'app-order-summary',
  templateUrl: './order-summary.page.html',
  styleUrls: ['./order-summary.page.scss'],
})
export class OrderSummaryPage implements OnInit {


    @Input() offlineCartArray: any = [];
    @Input() id: any = [];
    warehouses: any = [];
    selectedWarehouse: any;

    constructor(public modalController: ModalController, public api: ApiService, public router: Router) {
    }


    ngOnInit() {
      // this.getWarehouse();
    }

    getWarehouse() {
      // this.api.getWarehouse(this.id).subscribe((response: any) => {
      //   this.warehouses = response.data;
      //   this.selectedWarehouse = this.warehouses[0]._id;
      //   console.log(this.selectedWarehouse);
      // })
    }

    dismiss(data) {
      this.modalController.dismiss({
        'dismissed': true,
        data: data
      });
    }

    calculateTotalQty(brand) {
      var array = [];
      for (let x of brand) {
        for (let y of x.data) {
          for (let z of y.data) {
            for (let f of z.data) {
              array.push(f);
            }
          }
        }
      }
      return this.calcQty(array)
    }


    calcQty(data) {
      var array: any[] = data;
      var sum = 0;
      const source = from(array);
      const defectiveSum = source.pipe(reduce((acc, val) =>
        acc + (parseInt(val.input_qty) * parseInt(val.no_of_sets)), 0));
      defectiveSum.subscribe(x => sum = x);
      return sum
    }

    calcCOST(data) {
      var array: any[] = data;
      var sum = 0;
      const source = from(array);
      const defectiveSum = source.pipe(reduce((acc, val) =>
        acc + (val.cost * val.input_qty * val.no_of_sets), 0));
      defectiveSum.subscribe(x => sum = x);
      return sum
    }

    calculateTotalCOST(brand) {
      var array = [];
      for (let x of brand) {
        for (let y of x.data) {
          for (let z of y.data) {
            for (let f of z.data) {
              array.push(f);
            }
          }
        }
      }

      return this.calcCOST(array);

    }

    calcDiscount(data) {
      var array: any[] = data;
      var sum = 0;
      const source = from(array);
      const defectiveSum = source.pipe(reduce((acc, val) =>
        acc + (val.discount_amount * val.input_qty * val.no_of_sets), 0));
      defectiveSum.subscribe(x => sum = x);
      return sum
    }

    calculateTotalDiscount(brand) {
      var array = [];
      for (let x of brand) {
        for (let y of x.data) {
          for (let z of y.data) {
            for (let f of z.data) {
              array.push(f);
            }
          }
        }
      }
      return this.calcDiscount(array);
    }

    calculateTotalSum(brand, key) {
      var array = [];
      for (let x of brand) {
        for (let y of x.data) {
          for (let z of y.data) {
            for (let f of z.data) {
              array.push(f);
            }
          }
        }
      }
      return this.calcSum(array, key)
    }

    calculateTotalTax(brand) {
      var array = [];
      for (let x of brand) {
        for (let y of x.data) {
          for (let z of y.data) {
            for (let f of z.data) {
              array.push(f);
            }
          }
        }
      }
      return this.calcTax(array)
    }

    calcSum(data, key) {
      var array: any[] = data;
      var sum = 0;
      const source = from(array);
      const defectiveSum = source.pipe(reduce((acc, val) =>
        acc + val[key], 0));
      defectiveSum.subscribe(x => sum = x);
      return sum
    }

    calcTax(val) {

      var tax = 0;
      for (let x of val) {
        if (x.cost > 1049) {
          tax = x.cost * 12 / 100 * (parseInt(x.input_qty) * parseInt(x.no_of_sets));
        }
        else if (x.cost <= 1049) {
          tax = x.cost * 5 / 100 * (parseInt(x.input_qty) * parseInt(x.no_of_sets));
        }
        x['tax'] = tax;
      }

      return this.calcSum(val, 'tax');

    }

    // revieworder(brand) {
    //   var array = [];
    //   for (let x of brand) {
    //     for (let y of x.data) {
    //       for (let z of y.data) {
    //         for (let f of z.data) {
    //           if (f.no_of_sets * f.input_qty > f.available_qty) {
    //             f.input_qty = Math.floor(f.available_qty / f.no_of_sets);
    //           }
    //           else if (isNaN(f.input_qty)) {
    //             f.input_qty = 0;
    //           }
    //           array.push({
    //             "brand_id": f.brand_id,
    //             "EAN_code": f.EAN_code,
    //             "delivery_id": this.id,
    //             "retailer_id": this.api.retailerData[0]._id,
    //             "input_qty": f.input_qty,
    //             "no_of_sets": f.no_of_sets,
    //             "grade": f.grade,
    //             "MRP": f.MRP,
    //             "user_id": this.api.userData._id,
    //             "user_first_name": this.api.userData.userFirstName,
    //             "user_last_name": this.api.userData.userLastName
    //           })
    //         }
    //       }
    //     }
    //   }
    //   this.api.addtocart(array).subscribe(response => {
    //     this.dismiss(brand);
    //   });
    //
    // }

    // placeorder(brand) {
    //   this.revieworder(brand);
    //   var array = [];
    //   for (let x of brand) {
    //     for (let y of x.data) {
    //       for (let z of y.data) {
    //         for (let f of z.data) {
    //           array.push({
    //             'retailer_id': this.api.retailerData[0]._id,
    //             'delivery_id': this.id,
    //             'brand_id': f.brand_id,
    //             'EAN_code': f.EAN_code,
    //             'no_of_sets': f.no_of_sets,
    //             'approved_qty': f.input_qty,
    //             'available': true
    //           });
    //         }
    //       }
    //     }
    //
    //
    //     const body = new FormData();
    //     body.append('cartData', JSON.stringify(array));
    //     body.append('user_id', this.api.userData._id);
    //     body.append('inventory_bucket', '["On-Hand","BOX-NOT-OPEN","WIP-30","WIP-60"]');
    //     body.append('destination_inventory_bucket', "On-Hand");
    //     body.append('delivery_id', this.selectedWarehouse);
    //
    //     if (this.selectedWarehouse) {
    //     this.api.placeOrder(body).subscribe(response => {
    //         this.api.presentToast('Order Placed Successfully');
    //         this.router.navigate(['/home/orders']);
    //
    //       }, error => {
    //           this.api.presentToast('Order Not Placed, Try Again later');
    //       })
    //     }
    //     else {
    //       this.api.presentToast('Please Select Warehouse');
    //     }
    //   }
    // }


}
